import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { MainBaseService } from '../../../../core/services/main-base-service';
import { AuditInsightDraftReportListDTO } from '../dto/audit-insight-draft-report.dto';
import { AuditDraftReportDocumentDTO } from '../../../audit/audit/dto/audit-draft-report.dto';
import { AuditInsightDraftReportFindingActionPlanFormDTO } from '../dto/audit-insight-draft-report-finding-action.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditInsightDraftReportService extends MainBaseService<
AuditInsightDraftReportListDTO,
AuditDraftReportDocumentDTO
> {
  protected apiUrl = environment.apiUrl + '/audit-insights/draft-reports';

  constructor(http: HttpClient) {
    super(http);
  }

  acceptFinding(
    findingId: number,
    item: AuditInsightDraftReportFindingActionPlanFormDTO
  ): Observable<AuditInsightDraftReportFindingActionPlanFormDTO> {
    return this.http.post<AuditInsightDraftReportFindingActionPlanFormDTO>(
      `${this.apiUrl}/${findingId}/accept`,
      item
    );
  }
}
